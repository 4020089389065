<template>
    <div class="potentialClientDetails">
        <rx-navBar title="潜客详情"></rx-navBar>
        <div v-for="(item,i) in dataInfoList" :key="i">
        <div class="text">
            <div class="owner" style="margin-top: 10px">客户信息</div>
            <div class="displayBlock1">
                <div v-if="item.resourceStatus=='1'" class="valid stateOne">有效</div>
                <div v-if="item.resourceStatus=='2'" class="valid stateTwo">无效</div>
                <div v-if="item.resourceStatus=='3'" class="valid stateThree">已签</div>
                <div v-if="item.resourceStatus=='4'" class="valid stateFour"> 已定 </div>
                <table class="houseInfo">
                    <tr>
                        <td>
                            <div class="boldFont">姓名：<span class="normalFont">{{item.userName ? item.userName : '暂无'}}</span></div>
                        </td>
                        <td>
                            <div class="boldFont">电话：<span class="normalFont">{{item.mobile ? item.mobile : '暂无'}}</span></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="boldFont">性别：<span class="normalFont">{{item.gender==1 ? '男' : item.gender==0 ? '女' : '暂无' }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="boldFont">微信：<span class="normalFont">{{item.wechat ? item.wechat : '暂无'}}</span></div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="boldFont">重点需求：<span class="normalFont">{{item.keyNoteName ? item.keyNoteName : '暂无'}}</span>
                            </div>
                        </td>

                        <td>
                            <div class="boldFont">客户类型：<span
                                    class="normalFont">{{item.resourceBelog==1?'公客':'私客'}}</span></div>
                        </td>
                    </tr>
<!--                </table>-->

<!--                    <div class="rule"></div>-->
<!--                <table class="houseInfo">-->
                    <tr>
                        <td>
                            <div class="boldFont">维护人：<span class="normalFont">{{item.staffName ? item.staffName : '暂无'}}</span></div>
                        </td>
                        <td>
                            <div class="boldFont">录入人：<span class="normalFont">{{item.addStaffName ? item.addStaffName : '暂无'}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="boldFont">推荐人：<span class="normalFont">{{item.recommendUserName ? item.recommendUserName : '暂无'}}</span>
                            </div>
                        </td>

                        <td>
                            <div class="boldFont">来源：<span
                                    class="normalFont">{{item.potentialUserSourceName ? item.potentialUserSourceName : '暂无'}}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="boldFont">录入时间：<span class="normalFont">{{item.addTime ? item.addTime : '暂无'}}</span></div>
                        </td>

                    </tr>
                </table>
            </div>
        </div>
        <div class="text">
<!--            <div class="owner">需求信息</div>-->
            <div v-for="item in item.requireList" :key="item.id">
                <div class="owner">需求信息</div>
                <div class="displayBlock">
                    <table class="houseInfo">
                        <tr>
                            <td>
                                <div class="boldFont">商圈：<span class="normalFont">{{item.businessCircleName ? item.businessCircleName : '暂无' }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="boldFont">需求类型：<span class="normalFont">{{item.requireTypeName ? item.requireTypeName :'暂无'}}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="boldFont">租期：<span class="normalFont">{{item.rentTimeLongTime ? item.rentTimeLongTime : '暂无' }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="boldFontPrice">预计入住日期：<span class="normalFont">{{item.entryTime ? item.entryTime : '暂无'}}</span></div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <div class="boldFontPrice">价格区间：<span class="normalFont">{{item.price ? item.price+'/元' : '暂无'}}</span></div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div class="boldFont" style="width:100%">地址：<span
                                        class="normalFont">{{item.roomName ? item.roomName : '暂无'}}</span></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="displayBlock" style="margin: 15px 0">
                    <table class="houseInfo">
                        <tr>
                            <td>
                                <div class="boldFont">备注：</div>
                            </td>
                        </tr>
                        <tr>
                            <div class="normalFont">{{item.remarks ? item.remarks : '暂无'}}</div>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
        <div class="text" style="margin-bottom: 20px">
            <div class="owner">跟进信息</div>
            <div class="displayBlock">
                <div class="tailInfo">
                    <!--                进度条-->
                    <div class="bgPoint">
                        <div class="topVertical"></div>
                        <div class="outerCircle">
                            <div class="insideCircle"></div>
                        </div>
                        <div class="bottomVertical"></div>
                    </div>
                    <!--                跟踪信息-->
                    <div class="tailDetail" v-if="item.fuDetail">
                        <div class="taila">
                            <!--                    跟进图-->
                            <div class="tailb tail_follow">跟进</div>
                            <!--                    跟进时间-->
                            <span>{{item.fuTime}}</span>
                        </div>
                        <!--                    跟进内容 -->
                        <span class="tailc">{{item.fuDetail}}</span>
                    </div>
                    <div class="noInfo" v-else>
                        <span class="span2">暂无信息</span>
                    </div>
                    <!--           followupBtn() &&     跟踪按钮-->
                    <div v-if=" checkAuthList(authButtonsList,'followUp')">
                        <button class="tailBtn" @click="toAddFollowUp">历史跟进</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="btns_row">
            <div class="setBtnDiv">
                <button class="setBtn" v-if="switchToEffectiveBtn(item) && checkAuthList(authButtonsList,'effectiveOrInvalid')" @click="changeState(item) ">设为无效</button>
                <button class="setBtn setBtnPlc" v-if="switchToInvalidBtn(item) && checkAuthList(authButtonsList,'invalid')" @click="changeState(item)">设为有效</button>
                <button class="setBtn" v-if="switchToEffectiveBtn(item) && checkAuthList(authButtonsList,'bdEffective')" @click="changeState(item,'isBD') ">设为BD无效</button>
                <button class="setBtn setBtnPlc" v-if="switchToInvalidBtn(item) && checkAuthList(authButtonsList,'bdInvalid')" @click="changeState(item,'isBD')">设为BD有效</button>                
            </div>
            <div class="moreBtnDiv">
                <button class="moreBtn moreBtnPlc" v-if="item.resourceBelog == '1' && switchToMoreBtn(item) && checkAuthList(authButtonsList,'switchToPrivateService')" @click="showPopupWithPrv(item)">转为私客</button>
                <button class="moreBtn" v-if=" switchToMoreBtn(item) && item.resourceBelog=== '0' && checkAuthList(authButtonsList,'switchToPublicService')" @click="maskShow = true">转为公客</button>
            </div>
<!--            <button @click="getStaffList" >指派</button>-->
<!--            <button>预约</button>-->
        </div>


        <div class="bottom">
            <!-- 保证只有一个按钮时按钮在右边 -->
            <div></div>
            <div class="left_content">
                <div  @click="toNewContract" v-if="singnOrChangeBtn() && checkAuthList(authButtonsList,'signing')">
                    <img class="bottom_img" src="../../assets/images/potentialHouse-icon1.png" />
                    <div class="bottom_text">签约</div>
                </div>
            </div>
            <div class="bottom_reserveDiv" >
                <van-button class="bottom_reserve" @click="skipReserve" v-if="reserveBtn() && checkAuthList(authButtonsList,'reserve')">
                    预定
                </van-button>
            </div>
            <div class="bottom_changeDiv">
                <van-button class="bottom_change" @click="toChange" v-if="singnOrChangeBtn() && checkAuthList(authButtonsList,'modify')">
                    修改
                </van-button>
            </div>
            <div class="bottom_lookDiv">
                <van-button class="bottom_look" @click="toTakeALook" v-if="seeWithBtn() && checkAuthList(authButtonsList,'takeNotes')">
                    带看
                </van-button>
            </div>
        </div>
        <!-- 底部填充 -->
        <div :class=" resourceBelog==0 ?  'fillContent' : 'fillContentPlc'"></div>
        </div>
        <van-popup v-model="staffListShow" round position="bottom">
            <van-picker show-toolbar :columns="staffList" value-key="title" @cancel="staffListShow = false" @confirm="selectStaff"/>
        </van-popup>
        <!-- 转为无效 -->
        <van-popup v-model="switchToInvalidShow" position="bottom">
            <div class="popup_wrapper esc">
                <p class="popup_title" v-if="resourceStatus == 2">
                    <span class="colon_bg"></span>
                    <span class="colon_mes">确认将该房源信息转为{{isBD?'BD':''}}有效</span>
                </p>
                <p class="popup_title" v-else>
                    <span class="colon_bg"></span>
                    <span class="colon_mes">确认将该房源信息转为{{isBD?'BD':''}}无效</span>
                </p>
                <div class="sub_btn">
                    <van-button class="sub_button" size="large" @click="changeResourceStatus()">提交</van-button>
                </div>
            </div>
        </van-popup>
<!--       转公盘和私盘 -->
        <van-popup v-model="maskShow" position="bottom">
            <!-- 转为私盘 -->
            <div class="popup_wrapper" v-if="resourceBelog==1">
                <p class="popup_title">
                    <span class="colon_bg"></span>
                    <span class="colon_mes">确认将该资源状态转为私客</span>
                </p>
                <div class="drop_menu_wrapper">
                    <div class="left_menu">
                        维护人
                    </div>
                    <div class="right_menu" @click="staffListShow = true">
                        <span class="colon_mes">{{staffName}}</span>
                        <!--                        <img class="part-inputpart-row-right"-->
                        <!--                        :class=" staffListShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-rightArrow' "-->
                        <!--                        src="../../assets/images/triangle.png">-->
                    </div>
                    <div class="arrows"></div>
                </div>
                <div class="sub_btn">
                    <van-button class="sub_button" size="large" @click="publicToPrivate">提交</van-button>
                </div>
            </div>
            <!--            转为公盘-->
            <div class="popup_wrapper" v-show="resourceBelog == 0">
                <p class="popup_title">
                    <span class="colon_bg"></span>
                    <span class="colon_mes">确认将该资源状态转为公客</span>
                </p>
                <div class="sub_btn">
                    <van-button class="sub_button" size="large" @click="privateToPublic">提交</van-button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {
        NavBar,
        Search,
        DropdownMenu,
        DropdownItem,
        Button,
        Popup,
        Picker
    } from 'vant';
    import {
        queryPotentialUser,
        getStaffFuntionModelList,
        queryStaffList,
        changeResourceStatus,
        updatePotentialResourceBelog, followUpList, queryPotentialUserRequireRoomHouseId
    } from '../../getData/getData'
    import {
        getStaffId,
        globaluserId,
        responseUtil,
        checkAuthList
    } from '../../libs/rongxunUtil';
    import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
    import eventBus from '../../components/rongxun/rx-navBar/envbus'
    export default {
        components: {
            [Button.name]: Button,
            [NavBar.name]: NavBar,
            [Search.name]: Search,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Popup.name]: Popup,
            [Picker.name]: Picker,
            rxNavBar
        },
        name: "potentialClientDetails",
        methods: {
            //跳转预定页面
            skipReserve(){
                this.$router.push({
                    name : 'roomChoose',
                    query : {
                        userId : this.userId,
                        mobile:this.dataInfoList[0].mobile,
                        prId:this.dataInfoList[0].prId,//潜在id
                        roomId:this.dataInfoList[0].perContractRoomHouse_id,//预定房源id
                        flag:'user'//潜客标志
                    }
                })
            },
            // 签约和修改是否显示
            singnOrChangeBtn() {
                if (this.resourceBelog === "0" && (this.resourceStatus == 1 || this.resourceStatus == 4)) {
                    return true
                }
            },
            // 带看是否显示
            seeWithBtn() {
                if (this.resourceBelog === "0") {
                    return true
                }
            },
            // 预定是否显示
            reserveBtn() {
                if (this.resourceBelog === "0" && this.resourceStatus == 1) {
                    return true
                }
            },
            // 设为有效按钮是否显示
            switchToInvalidBtn(item) {
                    if (item.resourceStatus == 2) {
                        return true
                    }
            },
            // 设为无效按钮是否显示
            switchToEffectiveBtn(item) {
                    if (item.resourceStatus == 1) {
                        return true
                    }
            },
            // 转为公/私客按钮是否显示
            switchToMoreBtn(item) {
                if (item.resourceBelog === "0") {
                    if (item.resourceStatus == 2) {
                        return true
                    }
                }
                if (item.resourceBelog == 1) {
                    return true
                }
            },
            getHeight(){

            },
            getStaffFuntionModelList() {
                var that = this
                let data = {}
                data.staff_id = getStaffId()
                data.menuName = 'potentialUser_index'
                getStaffFuntionModelList(data).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.authButtonsList = response.data.data.data
                    })
                })
            },
            checkAuthList,
            init() {
                let that = this
                let initData = {}
                initData.id = that.prId
                initData.idList = that.prIdList
                initData.user_id = globaluserId()
                queryPotentialUser(initData).then(response => {
                    responseUtil.dealResponse(that, response, () => {
                        let data = response.data.data
                        if(that.resourceBelog == 0){
                            that.dataInfoList.push(data)
                            that.resourceBelog = data.resourceBelog
                            that.resourceStatus = data.resourceStatus
                            that.userName = data.userName
                        }else{
                            that.dataInfoList = data.requireList
                            that.resourceBelog = data.resourceBelog
                            that.resourceStatus = data.resourceStatus
                            that.userName = data.userName
                        }
                    })
                })
            },
            toAddFollowUp() {
                let that = this
                that.$router.push({
                    name: 'addFollowUp',
                    query: {
                        id: that.prId,
                        followUpType : '0'
                    }
                })
            },
            toTakeALook() {
                let that = this
                that.$router.push({
                    name: 'takeALook',
                    query: {
                        id: that.prId,
                        userId:that.userId,
                        userName:that.userName,
                        fromType: '1',
                    }
                })
            },
            toNewContract() {
                var that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.potentialResource_id = that.prId
                initData.resourceStatus = that.dataInfoList[0].resourceStatus,
                queryPotentialUserRequireRoomHouseId(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            let paid = 0;
                            let perContract = response.data.data.percontract
                            let perContractId;
                            if (perContract && perContract.id && perContract.id != null) {
                                perContractId = perContract.id
                                if(perContract.payType == '1'){
                                    paid = 1;
                                }
                            }
                            that.$router.push({
                                name: 'NewContract',
                                query: {
                                    prId: that.prId,
                                    mobile : that.dataInfoList[0].mobile,
                                    type : '3',
                                    paid: paid,
                                    perContractId:perContractId,
                                    resourceStatus : that.dataInfoList[0].resourceStatus,
                                    roomId:that.dataInfoList[0].perContractRoomHouse_id,//预定房源id
                                    isBD:that.$route.query.isBD,
                                    staffName:that.$route.query.staffName
                                }
                            })
                        })
                })
            },
            toChange() {
                var addType=''
                if(this.$route.query.isBD=='1'){
                    addType='2'
                }
                let that = this
                that.$router.push({
                    name: 'addPotentialClient',
                    query: {
                        id: that.prId,
                        type:addType,
                        mold:'change'
                    }
                })
            },
            // //  有效无效的弹窗显示
            showPopupWithPrv() {
                let that = this
                that.maskShow = true
                queryStaffList({}).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.staffList = response.data.data.data
                        that.staffName = that.staffList[0].staffName
                        that.staff_id = that.staffList[0].id
                    })
                })
            },
            //  公盘转私盘点击提交的方法
            publicToPrivate() {
                let that = this
                let initData = {}
                initData.id = that.prId
                initData.user_id = globaluserId()
                initData.staff_id = that.staff_id
                initData.resourceBelog = "0"
                initData.idList = that.prIdList
                updatePotentialResourceBelog(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,() => {
                        if(response.data.code == 0){
                            that.dataInfo.resourceBelog = initData.resourceBelog
                            that.maskShow = false
                            responseUtil.alertMsg(that,response.data.msg)
                            that.$router.go(-1);
                        }
                    })
                })
            },
            // 私客转公客
            privateToPublic() {
                let that = this
                let initData = {}
                initData.id = that.prId
                initData.user_id = globaluserId()
                initData.resourceBelog = "1"
                updatePotentialResourceBelog(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,() => {
                        if(response.data.code == 0){
                            that.dataInfo.resourceBelog = initData.resourceBelog
                            that.maskShow = false
                            that.$router.go(-1);
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })

            },
            changeState(item,type){
                console.log(item)
                if(type){
                   this.isBD='1'
                }else{
                    this.isBD=''
                }
                this.prId = item.prId
                this.resourceStatus= item.resourceStatus                
                if(this.isBD=='1'&&item.resourceStatus=='2'){
                    this.$router.push({
                      name: 'referrer',
                      query: {
                        type: 6,
                      },
                    })
                }
                else{
                  this.switchToInvalidShow = true
                }
            },

            changeResourceStatus(){
                let that = this
                let initData = {}
                initData.user_id = globaluserId()
                initData.id = that.prId
                if(this.isBD){
                   initData.isBD=this.isBD
                }
                if(this.matainStaff_id){
                    initData.matainStaff_id=this.matainStaff_id
                }
                initData.resourceStatus = that.resourceStatus == 1 ? '2' : '1'
                changeResourceStatus(initData).then(response=>{
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code == 0){
                            that.resourceStatus = initData.resourceStatus
                            that.switchToInvalidShow = false
                            responseUtil.alertMsg(this,response.data.msg)
                            that.dataInfoList = []
                            that.init()
                        }
                    })
                })
            },
            selectStaff(item){
                this.staff_id = item.id
                this.staffName = item.title
                this.staffListShow = false
            }
        },
  activated() {
    eventBus.$on(
      'referrer',
      function(data) {
          console.log(data)
         if(data.id){
            this.matainStaff_id=data.id
            this.switchToInvalidShow = true
         }
      }.bind(this)
    )
  }, 
  //如果前往的页面不是推荐人，则摧毁缓存
  beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'referrer') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
  },         
        data() {
            return {
                mobile : '',
                switchToInvalidShow:false,
                staffListShow:false,
                maskShow: false,
                authButtonsList: [],
                staffList:[],
                dataInfo: {},
                fuaddTime: '',
                fuaddStaffId: '',
                followUpDetail: '',
                resourceStatus: '',
                staffName:'',
                staff_id:'',
                prIdList:[],
                userId:'',//潜客id
                userName:'',//潜客姓名
                followUpList : {},
                dataInfoList : [],
                resourceBelog : '',
                isBD:'',
                matainStaff_id:''
            }
        },

        mounted() {
            console.log(this.$route.query)
            this.prId = this.$route.query.prId
            this.prIdList = this.$route.query.prIdList
            this.userId=this.$route.query.id;
            this.init()
            this.getHeight()
            this.getStaffFuntionModelList()
        }
    }
</script>

<style lang="less" scoped>

    .potentialClientDetails{
        /*padding-bottom: constant(safe-area-inset-bottom);*/
        /*padding-bottom: env(safe-area-inset-bottom);*/
    }
    .valid {
        width: 33px;
        height: 16px;
        line-height: 16px;
        border-radius: 0px 0px 8px;
        font-size: 10px;
        text-align: center;
        color: #fff;
        background: linear-gradient(to right, #66CB94, #28D3B0);
    }

    .bottom_look {
        // margin: 12px 0px 0px 15px;
        width: 80px;
        height: 40px;
        border-radius: 8px;
        margin: 10px 0;
        margin-right: 15px;
        background: linear-gradient(to right, #ffc274, #ff5D3B);
    }
    .bottom_lookDiv{
        width: 25%;
    }

    .left_content {
        width:25%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bottom_reserve {
        // margin: 12px 0px 0px 65px;
        width: 80px;
        height: 40px;
        margin: 10px 0;
        border-radius: 8px;
        margin-right: 15px;
        background: linear-gradient(to right, #7FCAFF, #3091FF);
    }
    .bottom_reserveDiv{
        width: 25%;
    }

    .bottom_change {
        width: 80px;
        height: 40px;
        border-radius: 8px;
        margin: 10px 0;
        margin-right: 15px;
        background: linear-gradient(to right, #FFC274, #f40)
    }
    .bottom_changeDiv{
        width: 25%;
    }
    .part-inputpart-row-right {
        float: right;
        margin-right: 20px;
        color: rgba(199, 199, 199, 1);
        width: 8px;
        margin-top: 14px;
    }

    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }
    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .bottom_text {
        width: 25px;
        height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular;
    }

    .bottom_img {
        padding: 5px;
        width: 20px;
        height: 20px;
    }

    .bottom {
        width: 100%;
        position: fixed;
        bottom: 0;
        background-color: white;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        /*padding-bottom: env(safe-area-inset-bottom);*/
    }

    .fillContent {
        height: 64px;
        visibility: hidden;
    }
    .fillContentPlc{
        height: 24px;
        visibility: hidden;
    }

    .house {
        padding-left: 17px;
        padding-bottom: 15px
    }

    .img1 {
        height: 50px;
        width: 20px;
    }

    img {
        width: 50px;
    }

    .owner {
        width: 48px;
        height: 17px;
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        text-align: right;
        margin-top: 30px;
        margin-bottom: 5px;
        font-family: PingFangSC-Semibold;
    }

    .text {
        /*margin-left: 15px;*/
        margin: 0 auto;
        width: 92%;
    }

    .displayBlock {
        display: flex;
        position: relative;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        width: 345px;
        height: auto;
    }

    .displayBlock1 {
        position: relative;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        width: 345px;
        height: auto;
    }

    .houseInfo {
        width: 345px;
        padding: 15px 15px 15px 15px;
    }

    .houseInfo2 {
        width: 345px;
        padding: 15px 15px 0px 15px;
    }
    .boldFont {
        font-size: 14px;
        font-weight: bold;
        height: 24px;
        width: 153px;
    }
    .boldFontPrice{
        font-size: 14px;
        font-weight: bold;
        height: 24px;
        width: 100%;
    }
    .normalFont {
        font-size: 13px;
        font-weight: normal;
        color: #999;
    }

    span {
        color: #999999;
        font-size: 12px;
    }

    .tailInfo {
        display: flex;

        justify-content: space-between;
        align-items: center;
        width: 92%;
        margin: 0 auto;

        img {
            width: 25px;
            object-fit: cover;
        }

        /*跟踪信息详情*/
        .tailDetail {
            margin-top: 24px;
            margin-left: 10px;
            width: 100%;
            /*border: 1px solid red;*/
            font-weight: 300;

            /*跟踪详情第一行DIV*/
            .taila {
                display: flex;

                /*跟进的DIV*/
                .tailb {
                    color: #FF5D3B;
                    background-color: #FFC0CB;
                    width: 35px;
                    text-align: center;
                    border-radius: 10px;
                    font-size: 10px;
                    line-height: 15px;
                    margin-right: 10px;

                    &.tail_follow {
                        background-color: rgba(49, 145, 255, 0.2);
                        color: rgb(49, 145, 255);
                    }
                }
            }

            /*跟踪描述问题的DIV*/
            .tailc {
                display: inline-block;
                line-height: 20px;
                text-indent: 10px;
                margin-top: 6px;
                padding-bottom: 20px;
            }

            span {
                color: #999999;
                font-size: 12px;
            }
        }

        /*跟踪信息按钮*/
        .tailBtn {
            width: 80px;
            height: 30px;
            /*flex-grow: 0.1;*/
            border: 1px solid transparent;
            background-image: linear-gradient(white, white),
                linear-gradient(to right, #FFC274, #FF5D3B);
            background-clip: padding-box, border-box;
            background-origin: border-box;
            border-radius: 8px;
            color: #FF5D3B;
            font-size: 12px;
            line-height: 20px;
        }
    }

    .bgPoint {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 70%;

        .outerCircle {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            border: 2px solid rgb(49, 145, 255);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;

            .insideCircle {
                width: 0px;
                height:0px;
                border: 4px solid rgb(49, 145, 255);
                border-radius: 50%;
            }
        }

        .topVertical {
            height: 100%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to bottom, rgba(49, 145, 255, .2), rgb(49, 145, 255));
        }

        .bottomVertical {
            height: 100%;
            width: 1.5px;
            border: none;
            background-image: linear-gradient(to top, rgba(49, 145, 255, .2), rgb(49, 145, 255));
        }
    }

    .btns_row {
        width: 88%;
        margin: 15px auto;
        /*border:1px solid red;*/
        display: flex;
        .setBtnDiv{
            width: 80%;
            /*border:1px solid red;*/
            text-align: left;
            .setBtn{
                font-size: 13px;
                height: 35px;
                width: 90px;
                line-height: 33px;
                border-radius: 8px;
                border: none;
                color: #bcbcbc;
                background-color: #f1f1f1;
            }
            .setBtnPlc{
                color: #ff5d3b;
                background-color: #fbded5;
            }
        }
        .moreBtnDiv{
            width: 50%;
            /*border:1px solid red;*/
            text-align: right;
            .moreBtn{
                font-size: 13px;
                height: 35px;
                width: 90px;
                line-height: 33px;
                border-radius: 8px;
                border: none;
                color: #bcbcbc;
                background-color: #f1f1f1;
            }
            .moreBtnPlc{
                color: #FF5D3B;
                background-image: -webkit-linear-gradient(white, white), -webkit-linear-gradient(left, #FFC274, #FF5D3B);
                background-image: linear-gradient(white, white), linear-gradient(to right, #FFC274, #FF5D3B);
                background-clip: padding-box, border-box;
                background-origin: border-box;
                border: 0.02667rem solid transparent;
            }
        }
    }

    /*待待待的跟踪信息*/
    .OthertailInfo {
        padding: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;

        span {
            font-size: 12px;
            width: 67%;
            color: #999999;
        }

        button {
            width: 80px;
            height: 30px;
            border-radius: 8px;
            font-size: 12px;
            background-image: linear-gradient(to right, #FFC274, #FF5D3B);
            border: none;
            color: #FFFFFF;
        }
    }

    // 公盘转私盘
    .popup_wrapper {
        width: 345px;
        padding: 15px;
        background-color: rgb(250, 250, 250);

        &.esc {
            background-color: rgb(255, 255, 255);
        }

        .invalid_message {
            .msg_body {
                border-radius: 8px;
                background-color: rgb(250, 250, 250);
            }
        }

        .colon_bg {
            position: absolute;
            display: block;
            width: 16px;
            height: 16px;
            background: url(../../assets/images/colon.png) no-repeat;
            background-size: 100% 100%;
        }

        .colon_mes {
            color: rgba(51, 51, 51, 1);
            font-size: 15px;
            display: inline-block;
            padding-left: 25px;
        }

        .popup_title {
            width: 150px;
            font-size: 16px;
            font-weight: bold;
            margin: 20px 0;
        }

        .drop_menu_wrapper {
            height: 50px;
            display: flex;
            align-items: center;
            background-color: rgb(255, 255, 255);
            border-radius: 8px;
            flex-wrap: nowrap;
            .left_menu {
                margin-left: 15px;
                font-weight: bolder;
                width: 80px;
                font-size: 14px;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: rgb(251, 91, 64);
                    position: relative;
                    top: -2px;
                    overflow: hidden;
                    margin-right: 5px;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    width: 2px;
                    height: 12px;
                    background-color: #ededed;
                    margin-left: 15px;
                }
            }

            .right_menu {
                width: 200px;
                display: flex;
                align-items: center;
                margin-left: 25px;
                font-size: 14px;
            }
            .arrows{
                height: 0;
                width: 0;
                border: 3px solid ;
                border-color: black transparent transparent transparent;
            }
        }

        .sub_btn {
            margin-top: 50px;

            .sub_button {
                border-radius: 8px;
                color: rgb(255, 255, 255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
            }


        }

    }

    .noInfo {
        width: 100%;
        margin-left: 15px;
        text-align: center;
        line-height: 110px;
    }

    .span2 {
        font-size: 13px;
        color: #999999;
        line-height: 24px;
        font-weight: normal;
    }

    .stateOne {
        background-image: linear-gradient(#66CB94, #28D3B0);
    }

    .stateTwo {
        background: rgb(174, 172, 172);
        // background-image: linear-gradient(#FFC274, #FF5D3B);
    }

    .stateThree {
        background: rgb(24, 144, 255);
        // background-image: linear-gradient(#FFC274, #FF5D3B);
    }

    .stateFour {
        background-image: linear-gradient(#FFC274, #FF5D3B);
    }
    .rule{
        width: 100%;
        border: 0.5px solid #ededed;
    }
</style>
